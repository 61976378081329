import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../../services/config.service';

@Injectable({
    providedIn: 'root'
})

export class LoginService {
    isLoggedIn: boolean = false;

    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) { }

    login(data: any): Observable<any> {
        const apiUrl = `${this.configService.configs.apiBaseUrl}/v2/${environment.login}`;
        // const apiUrl = `${this.configService.configs.apiBaseUrl}/${this.configService.configs.version}/${environment.login}`;
        return this.http.post(apiUrl, data);
    }

    checkLogin(token: any): Observable<any> {
        const apiUrl = `${this.configService.configs.apiBaseUrl}/v2/${environment.checkLogin}/` + token;
        return this.http.get(apiUrl);
    }

    employeeLogin(data: any): Observable<any> {
        const apiUrl = `${this.configService.configs.apiBaseUrl}/v2/${environment.employeeLogin}`;
        return this.http.post(apiUrl, data);
    }
}
