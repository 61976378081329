import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config as siteConfig } from 'src/sites/config';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient) { }

    setTheme() {
        return new Promise<void>((resolve, reject) => {
                const theme = siteConfig.theme;
                const logo = siteConfig.logo;
                Object.keys(theme).forEach((key) => {
                    this.document.documentElement.style.setProperty(`--${key}`, theme[key]);
                });
                this.setLogos(logo);
                const favIconLogo = logo.favicon;
                this.setThemeFavIcon(favIconLogo);
                const status: any = { status: true };
                resolve(status);
        });
    }

    setThemeFavIcon(source) {
        let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
        favIcon.href = source;
    }

    setLogos(logos) {
        Object.keys(logos).forEach((key) => {
            this.document.documentElement.style.setProperty(`--${key}Image`, `url(${logos[key]})`);
        });
    }
}
