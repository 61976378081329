export class ErrorConstants {
    public static ERROR_ADDRESS_REQUIRED = 'Address is required.';
    public static ERROR_EMAIL_REQUIRED = 'Email is required.';
    public static ERROR_VALID_EMAIL = 'Please enter a valid email';
    public static ERROR_VALID_ZIP_CODE = 'Enter a valid Zip Code.';
    public static ERROR_PASSWORD_REQUIRED = 'Password is required.';
    public static ERROR_USERNAME_REQUIRED = 'Username is required.';
    public static ERROR_FILE_REQUIRED = 'File is required.';
    public static TOKEN_HEADER_KEY = 'Authorization';
    public static ERROR_CURRENT_PASSWORD_REQUIRED = 'Current password is required.';
    public static ERROR_PASSWORD_PATTERN = 'Please enter a minimum of 8 characters combination of one uppercase, one lower case, one special character, and one digit.';
    public static ERROR_NEW_PASSWORD_REQUIRED = 'New password is required.';
    public static ERROR_CONFIRM_NEW_PASSWORD_REQUIRED = 'Confirm new password is required.';
    public static ERROR_CONFIRM_NEW_PASSWORD_DID_NOT_MATCH = 'Confirm  Password didn\'t match.';
    public static ERROR_NEW_PASSWORD_CANNOT_BE_SIMILAR = 'The current password and new password cannot be similar.';
    public static ERROR_NEW_PASSWORD_MINLENGTH = 'Use 8 character or more for your password..';
    public static ERROR_RECORD_NOT_FOUND = 'No record(s) found.';
    public static ERROR_ROUTING_NUMBER_REQUIRED = 'Routing number is required.';
    public static ERROR_VALID_ROUTING_NUMBER = 'Enter a valid Routing Number.';
    public static ERROR_ACCOUNT_NUMBER_REQUIRED = 'Account Number is required.';
    public static ERROR_VALID_ACCOUNT_NUMBER = 'Enter a valid Account Number.';
    public static ERROR_BANK_NAME_REQUIRED = 'Bank Name is required.';
    public static ERROR_ACCOUNT_TYPE_REQUIRED = 'Account Type is required.';
    public static ERROR_NAME_ON_ACCOUNT_REQUIRED = 'Name on Account is required.';
    public static ERROR_FIRST_NAME_ON_ACCOUNT_REQUIRED = 'First Name on Account is required.';
    public static ERROR_LAST_NAME_ON_ACCOUNT_REQUIRED = 'Last Name on Account is required.';
    public static ERROR_CREDIT_CARD_TYPE_REQUIRED = 'Credit Card Type is required.';
    public static ERROR_NAME_OF_THE_CARD_REQUIRED = 'Name on The Card is required.';
    public static COMMON_ERROR_MESSAGE = "Something went wrong. Please contact administrator.";
    public static VALID_BANK_ACCOUNT_DETAILS = 'Enter a valid bank account details.';
    public static VALID_CREDIT_CARD_DETAILS = 'Enter a valid credit card details.';
    public static ERROR_CARD_NUMBER_REQUIRED = 'Card number is required.';
    public static ERROR_VALID_CARD_NUMBER = 'Enter a valid credit card number.';
    public static ERROR_VALID_CREDIT_CARD_EXPIRY_DATE = 'Enter a valid credit card expiry date.'
    public static ERROR_VALID_CREDIT_CARD_CVV = 'Enter a valid cvv number.'
    public static CANCEL_THE_CHANGES = 'Are you sure you want to cancel the changes?'
    public static ERROR_PHONE_NUMBER = 'Phone number length should be 10 digit.'
    public static ERROR_FIRST_NAME_REQUIRED = 'First Name is required.';
    public static ERROR_LAST_NAME_REQUIRED = 'Last Name is required.';
    public static ERROR_CITY_REQUIRED = 'City is required.';
    public static ERROR_STATE_REQUIRED = 'State is required.';
    public static ERROR_ZIP_CODE_REQUIRED = 'Zip Code is required.';
    public static ERROR_IS_SECONDARY_PAYMENT_REQUIRED = 'Please choose the checkbox of secondary payment methods.';
    public static INVALID_AUTHENTICATION_TOKEN = 'Invalid authentication token.';
    public static PAYMENT_METHOD_ERROR_MESSAGE = "Unable to load payment details. Please contact administrator.";

    // Quick enroll form
    public static ERROR_GENDER_REQUIRED = 'Gender is required.';
    public static ERROR_MARITAL_STATUS_REQUIRED = 'Marital Status is required.';
    public static ERROR_CONFIRM_EMAIL_REQUIRED = 'Confirm Email is required.';
    public static ERROR_VALID_CONFIRM_EMAIL = 'Please enter a valid Confirm Email.';
    public static ERROR_MOBILE_PHONE_REQUIRED = 'Mobile Phone is required.';
    public static ERROR_MOBILE_PHONE_ONLY_NUMBERS = 'Mobile Phone only numeric values allowed.';
    public static ERROR_EMPLOYEE_SSN_REQUIRED = 'SSN is required.';
    public static ERROR_VALID_SSN = 'Please enter a valid SSN.';
    public static ERROR_EMPLOYEE_DOB_REQUIRED = 'Date of Birth is required.';
    public static ERROR_COMPANY_NAME_REQUIRED = 'Company Name is required.';
    public static ERROR_COMPANY_EIN_REQUIRED = 'EIN(Employer Identification Number) is required.';
    public static ERROR_EIN_INVALID = 'EIN only numeric values allowed.';
    public static ERROR_COMPANY_ENTITY_TYPE_REQUIRED = 'Entity Type is required.';
    public static ERROR_COMPANY_PHONE_REQUIRED = 'Company Phone is required.';
    public static ERROR_COMPANY_STREET_ADD_REQUIRED = 'Company Street Address is required.';
    public static ERROR_COMPANY_CITY_REQUIRED = 'Company City is required.';
    public static ERROR_COMPANY_STATE_REQUIRED = 'Company State is required.';
    public static ERROR_COMPANY_ZIP_REQUIRED = 'Company Zip Code is required.';
    public static ERROR_COMPANY_ASSOCIATION_NAME_REQUIRED = 'Association Name is required.';
    public static ERROR_AGENT_NUMBER_REQUIRED = 'Agent Number is required.';
    public static ERROR_ZIP_INVALID = 'Invalid Zip Code.';
    public static ERROR_ZIP_ONLY_NUMBERS = 'Zip Code only numeric values allowed.';
    public static ERROR_BILLING_EMAIL_REQUIRED = 'Billing Email is required.';
    public static ERROR_VALID_BILLING_EMAIL = 'Enter a valid Billing Email.';
    public static ERROR_VALID_PAYMENT_TYPE = 'Select Payment Type.';
    public static ERROR_EMAIL_CONFIRM_NOT_MATCH = 'Email confirmation does not match.';
    public static ADD_EMPLOYEE_DEPENDENT_RELATION_REQ = 'Relation is required.';
    public static ADD_EMPLOYEE_DEPENDENT_FIRST_NAME_REQ = 'First Name  is required.';
    public static ADD_EMPLOYEE_DEPENDENT_MIDDLE_NAME_REQ = 'Middle Name  is required.';
    public static ADD_EMPLOYEE_DEPENDENT_LAST_NAME_REQ = 'Last Name  is required.';
    public static ADD_EMPLOYEE_DEPENDENT_SUFFIX_REQ = 'Suffix  is required.';
    public static ADD_EMPLOYEE_DEPENDENT_DOB_REQ = 'Date Of Birth  is required.';
    public static ADD_EMPLOYEE_DEPENDENT_GENDER_REQ = 'Gender is required.';
    public static ADD_EMPLOYEE_DEPENDENT_SSN_REQ = 'SSN is required.';
    public static ADD_EMPLOYEE_SSN_INVALID = 'SSN is invalid.';
    public static ADD_EMPLOYEE_SSN_REQ = 'SSN is required.';
    public static ERROR_BILLING_SCHEDULE = 'Invalid Billing schedule, Please contact administrator..';
    public static ERROR_TOBACCO_REQUIRED = 'Tobacco is required.';

    public static ERROR_DISCLAIMER_REQUIRED = 'Please check the disclaimer.';
    public static PAYMENT_HAS_FAILED = "The payment has failed due to ";
    public static ALLOWED_PAYMENT_METHODS_NOT_CONFIGURED = "Allowed payment methods are not configured by the organization.";
    public static DEPENDENT_SSN_UNIQUE = "The dependent SSN must be unique.";
}
