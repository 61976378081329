import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderConfigService {

  constructor() { }

  private isQuickEnrollPageSubject = new BehaviorSubject<boolean>(false);
  isQuickEnrollPage$ = this.isQuickEnrollPageSubject.asObservable();

  setQuickEnrollPageState(state: boolean) {
    this.isQuickEnrollPageSubject.next(state);
  }

}
