import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

fetch('assets/config/config.json').then(response => {
    return response.json()
})
.then(response => {
    if (response.production) {
        enableProdMode();
    }
    platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
})