import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../http/auth/auth.service';

@Injectable({
	providedIn: 'root'
})

export class GuestGuard implements CanActivate {
	routeUrl: string = "dashboard";
	isLoggedIn: boolean = true;

	constructor(private authService: AuthService) { }

	canActivate(): boolean {
		return this.authService.checkUserSession(this.isLoggedIn, this.routeUrl);
	}
}