import { Injectable } from '@angular/core';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import { config as siteConfig } from 'src/sites/config';

@Injectable({
    providedIn: 'root',
})
export class LoaderScreenService {
    config: NgxUiLoaderConfig;

    constructor(private ngxUiLoaderService: NgxUiLoaderService) {
        if (this.config === undefined) {
            this.config = this.ngxUiLoaderService.getDefaultConfig();
        }
    }

    setLoaderConfigurations(isQuickEnrollPage: boolean): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const theme = siteConfig.theme;
            const ngxUiLoaderConfig: NgxUiLoaderConfig =
            {
                "overlayColor": theme['bs-text-white'],
                "bgsColor": theme['bs-secondary-color'],
                "fgsColor": theme['bs-secondary-color'],
                "pbColor": theme['bs-secondary-color'],
                "textColor": theme['bs-text-primary-color'],
                "logoUrl": isQuickEnrollPage ? '' : siteConfig.logo.loader,
                "bgsOpacity": 0.5,
                "bgsPosition": "bottom-right",
                "bgsSize": 100,
                "bgsType": "ball-spin-clockwise",
                "blur": 8,
                "delay": 0,
                "fastFadeOut": true,
                "fgsPosition": "center-center",
                "fgsSize": 50,
                "fgsType": "circle",
                "gap": 100,
                "logoPosition": "center-center",
                "logoSize": 50,
                "masterLoaderId": "master",
                "overlayBorderRadius": "0",
                "pbDirection": "ltr",
                "pbThickness": 3,
                "hasProgressBar": false,
                "text": "",
                "textPosition": "center-center",
                "maxTime": -1,
                "minTime": 300
            };

            this.config = ngxUiLoaderConfig;
            const status: any = { status: isQuickEnrollPage };
            resolve(status);
        });
    }
}
