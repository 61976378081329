export const config = {
    "theme": {
        "bs-primary-color": "#b80500",
        "bs-secondary-color": "#0e3269",
        // other color #6e6e72
        "bs-text-primary-color": "#0e3269",
        "bs-text-secondary-color": "#5c5c5c",
        "bs-text-white": "#fff",
        "bs-text-mandatory": "#ff0000",
        "bs-breadcrumb-color": "#ededed",
        "bs-active-breadcrumb-color": "#000000",
        "bs-disabled-color": "#ededed",
        "bs-border-color": "#ededed",
        "bs-hr": "#ededed",
        "bs-table-border": "#ededed",
        "bs-table-header": "#d7e7ea",
        "bs-table-row": "#f2f2f2",
        "bs-toast-header": "#d7e7ea",
        "bs-theme-background-color": "#0e3269"
    },
    "logo": {
        "loader": "assets/images/logo/ata/ataLoader.jpg",
        "main": "assets/images/logo/ata/ataMain.jpg",
        "favicon": "assets/images/favicon/ata/favicon.ico",
        "banner": "assets/images/banners/ss/banner.jpg",
        "loginBanner": "assets/images/background/ss/ss-bg-image.jpg"
    },
    "contact": {
        "copyright": "",
        "phone": "",
        "servicePhone": "",
        "salesPhone": "",
        "fax": "",
        "email": "",
        "serviceEmail": "",
        "salesEmail": "",
        "address": {
            "lineOne": "",
            "lineTwo": "",
            "lineThree": ""
        }
    },
    "configuration": {
        "siteTitle": "ATA America",
        "header": true,
        "footer": false
    }
};