<ngx-ui-loader
  [fgsColor]="loaderScreenService.config.fgsColor"
  [fgsPosition]="loaderScreenService.config.fgsPosition"
  [fgsSize]="loaderScreenService.config.fgsSize"
  [fgsType]="loaderScreenService.config.fgsType"
  [bgsColor]="loaderScreenService.config.bgsColor"
  [bgsOpacity]="loaderScreenService.config.bgsOpacity"
  [bgsPosition]="loaderScreenService.config.bgsPosition"
  [bgsSize]="loaderScreenService.config.bgsSize"
  [bgsType]="loaderScreenService.config.bgsType"
  [gap]="loaderScreenService.config.gap"
  [logoPosition]="loaderScreenService.config.logoPosition"
  [logoSize]="loaderScreenService.config.logoSize"
  [logoUrl]="loaderScreenService.config.logoUrl"
  [overlayColor]="loaderScreenService.config.overlayColor"
  [pbColor]="loaderScreenService.config.pbColor"
  [pbDirection]="loaderScreenService.config.pbDirection"
  [pbThickness]="loaderScreenService.config.pbThickness"
  [hasProgressBar]="loaderScreenService.config.hasProgressBar"
  [text]="loaderScreenService.config.text"
  [textColor]="loaderScreenService.config.textColor"
  [textPosition]="loaderScreenService.config.textPosition"
></ngx-ui-loader>
<div ngxUiLoaderBlurred blur="8"></div>
<router-outlet></router-outlet>
<a id="back-to-top" class="btn btn-light" role="button" *ngIf="topBtn" href="javascript:(0);"  (click)="topFunction()"><i class="fa fa-chevron-up"></i></a>