import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { APP_INITIALIZER } from '@angular/core';
import { ConfigService } from './core/services/config.service';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { DataTablesModule } from "angular-datatables";

export function initializeApp(config: ConfigService) {
    return () => config.load();
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        BreadcrumbModule,
        NgxUiLoaderModule,
        NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
        DataTablesModule
    ],
    providers: [{
        provide: APP_INITIALIZER,
        useFactory: initializeApp,
        deps: [
            ConfigService
        ],
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
