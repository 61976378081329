import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { AuthService } from '../http/auth/auth.service';
import { ErrorConstants } from 'src/app/shared/models/errorConstants';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../services/global.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private token: AuthService,
        private router: Router,
        private toastr: ToastrService,
        private global: GlobalService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = request;
        const token = this.token.getToken();
        if (token != null) {
            authReq = request.clone({ headers: request.headers.set(ErrorConstants.TOKEN_HEADER_KEY, 'Bearer ' + token) });
        }
        return next.handle(authReq).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.error.message)
                    this.toastr.warning(err.error.message);
                if ([401, 440].includes(err.status)) {
                    this.token.deleteToken();
                    this.router.navigateByUrl(`/login`);

                    /* IES-2967: After session expiration, the page reloads infinitely */
                    if (440 === err.status)
                        return EMPTY;

                    location.reload();
                } else {
                    this.global.loaderStop();
                    return throwError(err);
                }
                this.global.loaderStop();
            })
        );
    }
}
